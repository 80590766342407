import React, { useEffect, useState } from "react";

import NavBar from "../components/navbar/NavBar";

import Alvamalia from "../assets/images/team/alvamalia.png";
import David from "../assets/images/team/david.png";
import Harvey from "../assets/images/team/harvey.png";
import Hazz from "../assets/images/team/hazz.png";
import Jackson from "../assets/images/team/jackson.png";
import Jazy from "../assets/images/team/jazy.png";
import Khanhdao from "../assets/images/team/khanhdao.png";
import Kurniawan from "../assets/images/team/kurniawan.png";
import Milan from "../assets/images/team/milan.png";
import Nero from "../assets/images/team/nero.png";
import Ngakim from "../assets/images/team/ngakim.png";
import Wibi from "../assets/images/team/wibi.png";
import Rena from "../assets/images/team/rena.png";
import Frank from "../assets/images/team/frank.png";
import Bilal from "../assets/images/team/bilal.png";
import Carlo from "../assets/images/team/carlo.png";

const Home = () => {
  return (
    <>
      <div className="landing-page-main">
        <NavBar />
        <div className="main-hero">
          <div className="container-fluid">
            <div className="main-team-member-container">
              <div className="team-row-01">
                <div className="sub-team">
                  <img src={Harvey} alt="" />
                  <img src={Kurniawan} alt="" />
                  <img src={Wibi} alt="" />
                  <img src={Milan} alt="" />
                </div>
                <div className="sub-item-02">
                  <div className="ceo-sub">
                    <div className="main-ceo">
                      <a href="https://linktr.ee/khanhdao.bsc" target="_blank">
                        <img src={Khanhdao} alt="" />
                      </a>
                    </div>
                    <img src={Frank} alt="" />
                    <img src={Bilal} alt="" />
                  </div>
                  <div className="ceo-sub-02">
                    <a
                      href="https://www.linkedin.com/in/jazy-stark-3948b5216/"
                      target="_blank"
                    >
                      <img src={Jazy} alt="" />
                    </a>
                    <a>
                      <img src={Nero} alt="" />
                    </a>
                    <img src={Hazz} alt="" />
                  </div>
                </div>
              </div>
              <div className="team-row-02">
                <div className="sub-team">
                  <img src={Alvamalia} alt="" />
                  <img src={Ngakim} alt="" />
                  <img src={Jackson} alt="" />
                  <img src={Carlo} alt="" />
                  <img src={Rena} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
        {/* <ScrollTop /> */}
      </div>
    </>
  );
};

export default Home;
